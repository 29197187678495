import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

export default function AboutPage() {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleContactClick = () => {
    window.location.href = '/#contact';
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Back to home button */}
      <div className="fixed top-4 left-4 z-50">
        <Link 
          to="/"
          className="flex items-center text-primary hover:text-primary/80 transition-colors"
        >
          <ChevronLeftIcon className="w-5 h-5 mr-1" />
          {t('nav.home')}
        </Link>
      </div>

      <div className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold text-primary mb-8">{t('about.pageTitle')}</h1>
        
        <div className="max-w-4xl mx-auto">
          {/* Main Content */}
          <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
            <p className="text-gray-700 whitespace-pre-line">
              {t('about.content')}
            </p>
          </div>

          {/* Contact CTA */}
          <div className="text-center">
            <button 
              onClick={handleContactClick}
              className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
            >
              {t('quote.cta')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 