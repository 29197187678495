import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../../context/LanguageContext';
import { FerryCheckmark } from '../../components/FerryCheckmark';

// Import mockup if needed
import posterMockup from '../../assets/pics/FerrySafe-Website-Diensten-Posters-V01.jpg';
import posterMockup2 from '../../assets/posterMockup.png';

const content = {
  nl: {
    title: 'Creatieve veiligheidsposters',
    paragraphs: [
      'De creatieve veiligheidsposters van Ferry Safe zijn ontworpen om veiligheid op een aansprekende manier onder de aandacht te brengen. Met onze posters maken we veiligheid zichtbaar en toegankelijk voor iedereen op de werkvloer.',
      'De posters zijn beschikbaar in verschillende maten, hebben een eigen lay-out en worden verrijkt met handige iconen die de boodschap versterken.'
    ],
    features: [
      'Gepersonaliseerde ontwerpen',
      'Duidelijke veiligheidsboodschap',
      'Professionele kwaliteit',
      'Aanpasbaar aan uw huisstijl',
      'Verschillende formaten beschikbaar',
      'Snelle levering'
    ],
    featuresTitle: 'Kenmerken',
    cta: 'Neem contact op met Ferry Safe'
  },
  en: {
    title: 'Creative Safety Posters',
    paragraphs: [
      'The creative safety posters by Ferry Safe are designed to bring attention to safety in an engaging way. With our posters, we make safety visible and accessible for everyone in the workplace.',
      'The posters are available in various sizes, have their own layout and are enriched with useful icons that reinforce the message.'
    ],
    features: [
      'Personalized designs',
      'Clear safety message',
      'Professional quality',
      'Adaptable to your corporate identity',
      'Various formats available',
      'Quick delivery'
    ],
    featuresTitle: 'Features',
    cta: 'Contact Ferry Safe'
  }
};

export default function PostersPage() {
  const { language } = useLanguage();
  const currentContent = content[language];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white pt-20">
      {/* Back to home button */}
      <div className="fixed top-4 left-4 z-50">
        <Link 
          to="/"
          className="flex items-center text-primary hover:text-primary/80 transition-colors"
        >
          <ChevronLeftIcon className="w-5 h-5 mr-1" />
          {language === 'nl' ? 'Home' : 'Home'}
        </Link>
      </div>

      <div className="container mx-auto px-6 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-primary mb-6">{currentContent.title}</h1>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-12">
            <div className="relative h-64 md:h-96">
              <div className="absolute inset-0 bg-gradient-to-t from-primary/80 to-transparent z-10"></div>
              <img 
                src={posterMockup2} 
                alt="Safety Posters" 
                className="w-full h-full object-cover object-top"
              />
            </div>
            <div className="p-8">
              <div className="prose max-w-none">
                {currentContent.paragraphs.map((paragraph, index) => (
                  <p key={index} className="text-lg mb-6">
                    {paragraph}
                  </p>
                ))}
              </div>

              {/* Features Section */}
              <div className="mt-12">
                <h2 className="text-2xl font-bold text-primary mb-6">{currentContent.featuresTitle}</h2>
                <ul className="space-y-4">
                  {currentContent.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Example Posters Section */}
              <div className="mt-12">
                <h2 className="text-2xl font-bold text-primary mb-6">
                  {language === 'nl' ? 'Voorbeeld Posters' : 'Example Posters'}
                </h2>
                <div className="grid grid-cols-1 gap-8">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="text-primary/30 transform rotate-[-45deg] text-6xl font-bold">
                        {language === 'nl' ? 'VOORBEELD' : 'EXAMPLE'}
                      </div>
                    </div>
                    <img 
                      src={posterMockup} 
                      alt={language === 'nl' ? 'Voorbeeld Veiligheidsposter' : 'Example Safety Poster'} 
                      className="w-full rounded-lg shadow-lg"
                    />
                  </div>
                </div>
                <p className="text-gray-600 mt-4 text-center text-sm">
                  {language === 'nl' 
                    ? 'Dit is een voorbeeld van onze veiligheidsposters. Neem contact op voor meer voorbeelden en een poster op maat.' 
                    : 'This is an example of our safety posters. Contact us for more examples and custom posters.'}
                </p>
              </div>

              {/* CTA Section */}
              <div className="mt-12 text-center">
                <button 
                  onClick={() => {
                    window.location.href = '/#contact';
                    localStorage.setItem('scrollToContact', 'true');
                  }}
                  className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
                >
                  {currentContent.cta}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 