export const en = {
  nav: {
    home: 'Home',
    about: 'About',
    solutions: 'Solutions',
    testimonials: 'Testimonials',
    contact: 'Contact',
    pricing: 'Pricing'
  },
  hero: {
    title: 'Your\nvi<span className="font-extrabold text-accent">sion</span>,\non safety',
    subtitle: 'Working together towards a safer workplace through practical solutions and personal engagement',
    cta: 'View Our Services',
  },
  about: {
    title: 'About Ferry Safe',
    pageTitle: 'About Us',
    content: 'Ferry Safe is designed and developed to make safety more personal and visible within organizations.\n\nWorkplace safety is a top priority for me, Leen Mooldijk, founder of Ferry Safe. Years of practical experience have taught me that there is much to be gained in the field of safety. In addition to holding both Intermediate and Higher Safety Expert diplomas, I have over 10 years of experience in various safety-related roles within the transport and logistics sectors.\n\nMy extensive operational experience also allows me to assess safety from the perspective of the work floor. It is essential that established work instructions and procedures are actually workable in practice and that both management and employees on the work floor are committed to them.\n\nFerry Safe can function as a \'Safety Culture Model\' within organizations to give safety the attention it requires.\n\nIf you are inspired by Ferry Safe\'s vision and are interested in any of Ferry Safe\'s services, please get in touch. Together, we can explore what Ferry Safe can mean for your organization, without any obligation.\n\nBest regards,\n\nLeen Mooldijk\nHigher Safety Expert\nFounder of Ferry Safe'
  },
  solutions: {
    title: 'Our Services',
    safetyApp: {
      title: 'Ferry Safe Safety App',
      features: [
        'Real-time incident reporting',
        'Photo documentation',
        'Status tracking and analytics',
      ],
    },
    presentation: {
      title: 'Interactive Presentation',
      features: [
        'Gamified learning experience',
        'Interactive quiz elements',
        'Escape room components',
      ],
    },
    arboAdvice: {
      title: 'Workplace Safety Advice',
      description: 'Ferry Safe helps organizations make workplaces safer through risk analyses, policy advice, and training. Together we ensure a safe and productive environment.',
      features: [
        'Customized risk analyses',
        'Practical policy advice',
        'Safety training',
        'Culture analysis',
        'Compliance checks',
        'Implementation guidance'
      ]
    },
    posters: {
      title: 'Creative Safety Posters',
      description: 'The creative safety posters by Ferry Safe are designed to bring attention to safety in an engaging way.',
      features: [
        'Personalized designs',
        'Clear safety message',
        'Professional quality',
        'Adaptable to your corporate identity',
        'Various formats available',
        'Quick delivery'
      ]
    },
  },
  testimonials: {
    title: 'What Our Clients Say',
    testimonial1: {
      text: 'FerrySafe has transformed how we approach workplace safety. The app has made reporting incidents effortless.',
      author: '- John Smith, Safety Manager',
    },
    testimonial2: {
      text: 'The interactive presentations have significantly improved employee engagement in our safety training sessions.',
      author: '- Sarah Johnson, HR Director',
    },
    testimonial3: {
      text: 'Since implementing FerrySafe solutions, we\'ve seen a 40% reduction in workplace incidents.',
      author: '- Michael Brown, Operations Manager',
    },
  },
  contact: {
    title: 'Get in Touch',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send Message',
    namePlaceholder: 'Your name',
    emailPlaceholder: 'Your email',
    messagePlaceholder: 'Your message',
  },
  footer: {
    quickLinks: 'Quick Links',
    contact: 'Contact',
    followUs: 'Follow Us',
    rights: 'All rights reserved.',
    trademark: 'FerrySafe® is a registered trademark at the Benelux Office for Intellectual Property under registration number 1346619.'
  },
  pricing: {
    title: 'Pricing',
    subtitle: 'Choose the plan that fits your organization',
    app: {
      title: 'Safety App',
      starter: {
        name: 'Starter',
        price: '€4.99',
        period: 'per user / month',
        description: 'Perfect for small teams starting with safety management',
        users: 'Up to 25 users',
        features: [
          'All basic safety features',
          'Incident reporting',
          'Photo documentation',
          'Basic analytics',
          'Email support'
        ],
        cta: 'Get started'
      },
      professional: {
        name: 'Professional',
        price: '€3.99',
        period: 'per user / month',
        description: 'Most chosen for medium-sized organizations',
        users: '25-100 users',
        features: [
          'All Starter features',
          'Advanced analytics',
          'Team management',
          'API access',
          'Priority support'
        ],
        popular: 'Most popular',
        cta: 'Get started'
      },
      enterprise: {
        name: 'Enterprise',
        description: 'For large organizations with specific needs',
        users: '100+ users',
        features: [
          'All Professional features',
          'Custom integrations',
          'Dedicated support',
          'SLA guarantee',
          'On-premise option'
        ],
        cta: 'Contact us'
      }
    },
    training: {
      title: 'Interactive Training',
      description: 'Our interactive safety training is customized for your organization',
      features: [
        'Personalized learning experience',
        'Custom scenarios',
        'Full reporting',
        'Certification available',
        'Unlimited participants'
      ],
      cta: 'Request quote'
    }
  },
  purchase: {
    back: 'Back to pricing',
    steps: {
      users: 'Users',
      payment: 'Payment',
      company: 'Company',
      invite: 'Invite'
    },
    calculator: {
      title: 'Calculate number of users',
      recommendation: 'Recommendations for number of users:',
      officeRecommendation: 'Office workers: Managers, HR, administration, and other office staff who need to view and analyze incidents.',
      fieldRecommendation: 'Field workers: All employees active on the work floor who need to be able to report incidents.',
      officeUsers: 'Number of office users',
      fieldUsers: 'Number of field users',
      officeExample: 'e.g., managers, HR, administration',
      fieldExample: 'e.g., operators, mechanics, warehouse workers',
      next: 'Next step'
    },
    payment: {
      title: 'Overview and payment',
      officeUsers: 'Office users',
      fieldUsers: 'Field users',
      pricePerUser: 'Price per user per month',
      monthlyTotal: 'Monthly total',
      pay: 'Proceed to payment',
      processing: 'Processing...'
    },
    company: {
      title: 'Company information',
      name: 'Company name',
      address: 'Address',
      city: 'City',
      postalCode: 'Postal code',
      country: 'Country',
      vatNumber: 'VAT number',
      contactPerson: 'Contact person',
      phone: 'Phone number',
      next: 'Next step'
    },
    emails: {
      title: 'Add users',
      userEmail: 'User email address',
      complete: 'Complete'
    }
  },
  services: {
    title: 'Our Services',
    subtitle: 'Discover how we can help your organization',
    features: 'Features',
    benefits: 'Benefits',
    arbo: {
      title: 'Workplace Safety Advice',
      description: 'We help your organization create safer workplaces through risk analyses, policy advice, and training. Together we ensure a safe and productive environment.',
      cta: 'Request a consultation'
    },
    posters: {
      title: 'Creative Posters',
      description: 'Promote workplace safety with eye-catching, custom-made posters for your building and premises.',
      cta: 'View examples'
    },
    app: {
      title: 'Safety App',
      description: 'With our safety app, employees can easily report unsafe situations, add photos, and track solutions. Perfect for a safer work environment.',
      cta: 'Read more'
    },
    training: {
      title: 'Interactive Training',
      description: 'Engage and train your team with our gamified safety presentations and escape room elements. A fun and effective way to increase awareness.',
      cta: 'More information'
    }
  },
  quote: {
    title: 'Request a Quote',
    subtitle: 'Receive a customized proposal for your organization',
    services: {
      title: 'Our Services',
      app: 'Custom digital safety management',
      training: 'Engaging safety training',
      arbo: 'Professional risk analysis and advice',
      posters: 'Custom visual communication'
    },
    benefits: {
      title: 'Why Contact Us?',
      personal: 'Personalized advice',
      flexible: 'Flexible solutions for every budget',
      free: 'Free initial consultation',
      fast: 'Quick response time'
    },
    cta: 'Contact us'
  }
}; 