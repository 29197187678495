import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../../context/LanguageContext';
import { FerryCheckmark } from '../../components/FerryCheckmark';

// Import mockup if needed
import avgMockup from '../../assets/pics/FerrySafe-Website-Diensten-Advies-V01.jpg';

const content = {
  nl: {
    title: 'Advies over Arbo-veiligheid',
    paragraphs: [
      'Ferry Safe helpt organisaties om werkplekken veiliger te maken door het uitvoeren van risicoanalyses, het geven van beleidsadvies en trainingen. Samen zorgen wij voor een veilige en productieve omgeving.',
      'Elk bedrijf is uniek en beschikt vaak over een eigen veiligheidscultuur. De aanpak verschilt daardoor per bedrijf. Zodoende moet er gekeken worden naar de specifieke situaties en behoeften. Er wordt niet alleen gekeken naar het voldoen aan de wettelijke verplichtingen, maar ook naar praktische oplossingen die werken voor uw organisatie.'
    ],
    features: [
      'Risicoanalyses op maat',
      'Praktisch beleidsadvies',
      'Veiligheidstrainingen',
      'Cultuuranalyse',
      'Compliance checks',
      'Implementatiebegeleiding'
    ],
    featuresTitle: 'Kenmerken',
    cta: 'Neem contact op met Ferry Safe'
  },
  en: {
    title: 'Workplace Safety Advice',
    paragraphs: [
      'Ferry Safe helps organizations make workplaces safer through risk analyses, policy advice, and training. Together we ensure a safe and productive environment.',
      'Each company is unique and often has its own safety culture. The approach therefore differs per company. As such, specific situations and needs must be considered. We look not only at compliance with legal obligations but also at practical solutions that work for your organization.'
    ],
    features: [
      'Customized risk analyses',
      'Practical policy advice',
      'Safety training',
      'Culture analysis',
      'Compliance checks',
      'Implementation guidance'
    ],
    featuresTitle: 'Features',
    cta: 'Contact Ferry Safe'
  }
};

export default function ArboAdvicePage() {
  const { language } = useLanguage();
  const currentContent = content[language];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white pt-20">
      {/* Back to home button */}
      <div className="fixed top-4 left-4 z-50">
        <Link 
          to="/"
          className="flex items-center text-primary hover:text-primary/80 transition-colors"
        >
          <ChevronLeftIcon className="w-5 h-5 mr-1" />
          {language === 'nl' ? 'Home' : 'Home'}
        </Link>
      </div>

      <div className="container mx-auto px-6 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-primary mb-6">{currentContent.title}</h1>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-12">
            <div className="relative h-64 md:h-96">
              <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
              <img 
                src={avgMockup} 
                alt="Arbo Safety Advice" 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-8">
              <div className="prose max-w-none">
                {currentContent.paragraphs.map((paragraph, index) => (
                  <p key={index} className="text-lg mb-6">
                    {paragraph}
                  </p>
                ))}
              </div>

              {/* Features Section */}
              <div className="mt-12">
                <h2 className="text-2xl font-bold text-primary mb-6">{currentContent.featuresTitle}</h2>
                <ul className="space-y-4">
                  {currentContent.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* CTA Section */}
              <div className="mt-12 text-center">
                <button 
                  onClick={() => {
                    window.location.href = '/#contact';
                    localStorage.setItem('scrollToContact', 'true');
                  }}
                  className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
                >
                  {currentContent.cta}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 