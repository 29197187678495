import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../../context/LanguageContext';
import { FerryCheckmark } from '../../components/FerryCheckmark';

// Import mockup and screenshots
import appMockup from '../../assets/appMockup.png';
import appScreen1 from '../../assets/AppPics/SafetyApp1.png';
import appScreen2 from '../../assets/AppPics/SafetyApp2.png';
import appScreen3 from '../../assets/AppPics/SafetyApp3.png';
import appScreen4 from '../../assets/AppPics/SafetyApp4.png';

const screenshots = [appScreen1, appScreen2, appScreen3, appScreen4];

const content = {
  nl: {
    title: 'Ferry Safe Safety App',
    paragraphs: [
      'De Ferry Safe Safety App is ontwikkeld om het melden van onveilige situaties eenvoudig en laagdrempelig te maken. Gebruikers kunnen snel meldingen doen van onveilige situaties en daarbij foto\'s toevoegen om de situatie te verduidelijken. Vaak zegt een foto immers meer dan duizend woorden.',
      'Het voordeel van deze app is dat meldingen direct op de locatie gedaan kunnen worden, zonder dat er eerst een QR-code gescand hoeft te worden. Dit zorgt voor een snellere en minder complexe manier van melden.',
      'Om het melden zo laagdrempelig mogelijk te houden, worden de meldingen anoniem ingediend. Hierdoor worden de meldingen niet gekoppeld aan de melder, wat uit praktijkervaring blijkt te zorgen voor minder terughoudendheid bij het doen van een melding wanneer er geen naam aan verbonden is.',
      'Alle meldingen worden geregistreerd in een overzichtelijke database, waartoe de verantwoordelijke persoon of afdeling voor veiligheid binnen de organisatie toegang heeft. Het is van groot belang om deze database dagelijks te raadplegen, zodat er indien nodig direct actie kan worden ondernomen of de meldingen meegenomen kunnen worden in een overleg. Daarnaast is het aan te raden om wekelijks of maandelijks de meldingen te bespreken in het managementoverleg, om te kijken of er trends te ontdekken zijn.',
      'Het uiteindelijke doel van het melden van onveilige situaties is om incidenten in de toekomst te verminderen of zelfs volledig te voorkomen.'
    ],
    features: [
      'Eenvoudig en laagdrempelig melden',
      'Direct melden op locatie zonder QR-code',
      'Foto\'s toevoegen aan meldingen',
      'Anoniem melden voor meer openheid',
      'Overzichtelijke database voor management',
      'Trendanalyse mogelijkheden'
    ],
    featuresTitle: 'Kenmerken',
    cta: 'Neem contact op voor meer informatie'
  },
  en: {
    title: 'Ferry Safe Safety App',
    paragraphs: [
      'The Ferry Safe Safety App is developed to make reporting unsafe situations simple and accessible. Users can quickly report unsafe situations and add photos to clarify the situation. After all, a picture often says more than a thousand words.',
      'The advantage of this app is that reports can be made directly on location, without having to scan a QR code first. This ensures a faster and more user-friendly way of reporting.',
      'To keep reporting as accessible as possible, reports are submitted anonymously. This means that reports are not linked to the reporter, which from practical experience appears to result in less hesitation when making a report when no name is attached to it.',
      'All reports are registered in a clear database, to which the person or department responsible for safety within the organization has access. It is very important to consult this database daily, so that action can be taken immediately if necessary or the reports can be included in a meeting. In addition, it is recommended to discuss the reports weekly or monthly in the management meeting to look for trends.',
      'The ultimate goal of reporting unsafe situations is to reduce or even completely prevent incidents in the future.'
    ],
    features: [
      'Simple and accessible reporting',
      'Direct reporting on location without QR code',
      'Add photos to reports',
      'Anonymous reporting for more openness',
      'Clear database for management',
      'Trend analysis capabilities'
    ],
    featuresTitle: 'Features',
    cta: 'Contact us for more information'
  }
};

export default function SafetyAppPage() {
  const { t, language } = useLanguage();
  const currentContent = content[language];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % screenshots.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + screenshots.length) % screenshots.length);
  };

  const openLightbox = (index) => {
    setLightboxImage(screenshots[index]);
    setLightboxOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImage(null);
    document.body.style.overflow = 'unset';
  };

  return (
    <div className="min-h-screen bg-white pt-20">
      {/* Back to home button */}
      <div className="fixed top-4 left-4 z-50">
        <Link 
          to="/"
          className="flex items-center text-primary hover:text-primary/80 transition-colors"
        >
          <ChevronLeftIcon className="w-5 h-5 mr-1" />
          {t('nav.home')}
        </Link>
      </div>

      <div className="container mx-auto px-6 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-primary mb-6">{currentContent.title}</h1>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-12">
            <div className="relative h-auto">
              <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
              <div className="relative">
                <img
                  src={screenshots[currentSlide]}
                  alt={`App Screenshot ${currentSlide + 1}`}
                  className="w-full cursor-pointer"
                  onClick={() => openLightbox(currentSlide)}
                />
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full z-20"
                  onClick={(e) => {
                    e.stopPropagation();
                    prevSlide();
                  }}
                >
                  <ChevronLeftIcon className="w-6 h-6 text-primary" />
                </button>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full z-20"
                  onClick={(e) => {
                    e.stopPropagation();
                    nextSlide();
                  }}
                >
                  <ChevronRightIcon className="w-6 h-6 text-primary" />
                </button>
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-20">
                  {screenshots.map((_, index) => (
                    <button
                      key={index}
                      className={`w-2.5 h-2.5 rounded-full transition-colors ${
                        index === currentSlide ? 'bg-white' : 'bg-white/50'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentSlide(index);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="p-8">
              <div className="prose max-w-none">
                {currentContent.paragraphs.map((paragraph, index) => (
                  <p key={index} className="text-lg mb-6">
                    {paragraph}
                  </p>
                ))}
              </div>

              {/* Features Section */}
              <div className="mt-12">
                <h2 className="text-2xl font-bold text-primary mb-6">{currentContent.featuresTitle}</h2>
                <ul className="space-y-4">
                  {currentContent.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* CTA Section */}
              <div className="mt-12 text-center">
                <button 
                  onClick={() => {
                    window.location.href = '/#contact';
                    localStorage.setItem('scrollToContact', 'true');
                  }}
                  className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
                >
                  {currentContent.cta}
                </button>
              </div>
            </div>
          </div>

          {/* Lightbox */}
          {lightboxOpen && (
            <div
              className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4"
              onClick={closeLightbox}
            >
              <button
                className="absolute top-4 right-4 text-white hover:text-gray-300"
                onClick={closeLightbox}
              >
                <XMarkIcon className="w-8 h-8" />
              </button>
              <img
                src={lightboxImage}
                alt="App Screenshot Full Size"
                className="max-h-[90vh] max-w-[90vw] object-contain"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 