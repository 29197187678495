import React, { useState, useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { QuestionMarkCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="flex justify-center mb-8">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`w-3 h-3 rounded-full mx-1 ${
            index <= currentStep ? 'bg-primary' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

const UserCalculator = ({ onNext, selectedPlan }) => {
  const { t } = useLanguage();
  const [officeUsers, setOfficeUsers] = useState(0);
  const [fieldUsers, setFieldUsers] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (officeUsers + fieldUsers === 0) {
      return; // Toon een foutmelding
    }
    onNext({ officeUsers, fieldUsers });
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h3 className="text-2xl font-bold text-primary mb-6">{t('purchase.calculator.title')}</h3>
      
      <div className="bg-gray-50 p-4 rounded-lg mb-6">
        <div className="flex items-start mb-2">
          <QuestionMarkCircleIcon className="w-6 h-6 text-primary mr-2 flex-shrink-0" />
          <p className="text-sm text-gray-600">{t('purchase.calculator.recommendation')}</p>
        </div>
        <ul className="list-disc list-inside text-sm text-gray-600 ml-8">
          <li>{t('purchase.calculator.officeRecommendation')}</li>
          <li>{t('purchase.calculator.fieldRecommendation')}</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 mb-2">
            {t('purchase.calculator.officeUsers')}
            <span className="text-sm text-gray-500 ml-2">
              ({t('purchase.calculator.officeExample')})
            </span>
          </label>
          <input
            type="number"
            min="0"
            value={officeUsers}
            onChange={(e) => setOfficeUsers(parseInt(e.target.value) || 0)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 mb-2">
            {t('purchase.calculator.fieldUsers')}
            <span className="text-sm text-gray-500 ml-2">
              ({t('purchase.calculator.fieldExample')})
            </span>
          </label>
          <input
            type="number"
            min="0"
            value={fieldUsers}
            onChange={(e) => setFieldUsers(parseInt(e.target.value) || 0)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-primary hover:bg-primary/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
        >
          {t('purchase.calculator.next')}
        </button>
      </form>
    </div>
  );
};

const PaymentForm = ({ userCount, plan }) => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const totalUsers = userCount.officeUsers + userCount.fieldUsers;
  const pricePerUser = totalUsers >= 25 ? 3.99 : 4.99;
  const monthlyTotal = totalUsers * pricePerUser;

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        setLoading(true);
        const response = await fetch('http://localhost:3001/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: totalUsers >= 25 ? 'PROFESSIONAL' : 'STARTER',
            quantity: totalUsers
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create checkout session');
        }

        const { sessionId } = await response.json();
        console.log('Got session ID:', sessionId);

        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId
        });

        if (error) {
          throw error;
        }
      } catch (err) {
        console.error('Payment initialization error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeCheckout();
  }, [totalUsers]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
        <button
          onClick={() => window.location.reload()}
          className="w-full bg-primary hover:bg-primary/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
        >
          {t('payment.tryAgain')}
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="mb-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-4">{t('payment.summary')}</h3>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between mb-4">
            <span className="text-gray-600">{t('payment.users')}</span>
            <span className="font-medium">{totalUsers}</span>
          </div>
          <div className="flex justify-between mb-4">
            <span className="text-gray-600">{t('payment.pricePerUser')}</span>
            <span className="font-medium">€{pricePerUser.toFixed(2)}</span>
          </div>
          <div className="border-t pt-4 flex justify-between">
            <span className="text-lg font-medium">{t('payment.monthlyTotal')}</span>
            <span className="text-lg font-bold">€{monthlyTotal.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PurchaseFlow = ({ plan, onCancel }) => {
  const { t } = useLanguage();
  const [step, setStep] = useState(0);
  const [userCount, setUserCount] = useState(null);

  const handleUserCount = (count) => {
    setUserCount(count);
    setStep(1);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto">
        <div className="flex items-center mb-8">
          <button
            onClick={onCancel}
            className="flex items-center text-gray-600 hover:text-primary transition-colors"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            {t('purchase.back')}
          </button>
        </div>

        <StepIndicator currentStep={step} totalSteps={2} />

        {step === 0 && <UserCalculator onNext={handleUserCount} selectedPlan={plan} />}
        {step === 1 && <PaymentForm userCount={userCount} plan={plan} />}
      </div>
    </div>
  );
}; 