import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronDownIcon, CheckCircleIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../context/LanguageContext';
import { sendEmail } from '../utils/emailService';
import { FerryCheckmark } from '../components/FerryCheckmark';

// Import assets
import ferrySafeLogo from '../assets/FerrySafe-Hoofdlogo.png';
import avgMockup from '../assets/pics/FerrySafe-Website-Diensten-Advies-V01.jpg';
import posterMockup from '../assets/pics/FerrySafe-Website-Diensten-Posters-V01.jpg';
import appMockup from '../assets/pics/FerrySafe-Website-Diensten-App-V01.png';
import presMockup from '../assets/pics/FerrySafe-Website-Diensten-Presentatie-V01.jpg';

export default function HomePage() {
  const { t, language } = useLanguage();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Check if we need to scroll to contact section
    const shouldScrollToContact = localStorage.getItem('scrollToContact');
    if (shouldScrollToContact === 'true') {
      localStorage.removeItem('scrollToContact');
      setTimeout(() => {
        const contactElement = document.getElementById('contact');
        if (contactElement) {
          contactElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    const result = await sendEmail(formData, 'Homepage Contact');
    
    if (result.success) {
      setStatus({
        type: 'success',
        message: language === 'nl' ? 
          'Bedankt voor uw bericht. We nemen zo spoedig mogelijk contact met u op.' :
          'Thank you for your message. We will contact you as soon as possible.'
      });
      setFormData({ name: '', email: '', message: '' });
    } else {
      setStatus({
        type: 'error',
        message: language === 'nl' ?
          'Er is iets misgegaan. Probeer het later opnieuw of neem contact met ons op via email.' :
          'Something went wrong. Please try again later or contact us via email.'
      });
    }
    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  return (
    <>
      {/* Hero Section */}
      <section id="home" className="relative min-h-screen overflow-hidden pt-20 md:pt-0">
        <div className="absolute inset-0 bg-gradient-to-br from-primary to-primary/100" />
        <div className="relative z-10 h-full flex flex-col">
          <div className="flex-1 container mx-auto px-6">
            <div className="h-full flex flex-col md:flex-row items-center md:items-end justify-center pb-16 md:pb-32">
              <div className="w-full md:w-1/2 text-center md:text-left text-white pt-8 md:pt-0 md:mb-16">
                <div className="max-w-xl">
                  <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
                    {language === 'nl' ? (
                      <>
                        <span>Uw ge<span className="font-extrabold text-accent">zicht</span></span>
                        <br />
                        <span>op veiligheid</span>
                      </>
                    ) : (
                      <>
                        <span>Your <span className="font-extrabold text-accent">vision</span></span>
                        <br />
                        <span>on safety</span>
                      </>
                    )}
                  </h1>
                  <p className="text-lg sm:text-xl mb-8">
                    {t('hero.subtitle')}
                  </p>
                  <a href="#solutions">
                    <button className="bg-accent hover:bg-accent/90 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105">
                      {t('hero.cta')}
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 relative flex items-end justify-end mt-12 md:mt-0">
                <img 
                  src={ferrySafeLogo} 
                  alt="FerrySafe Mascot" 
                  className="h-[60vh] md:h-[80vh] w-auto object-contain object-bottom"
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-10 hidden md:block">
            <ChevronDownIcon className="w-8 h-8 text-white animate-bounce" />
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t('about.title')}</h2>
          <div className="max-w-3xl mx-auto">
            <div className="bg-white rounded-lg shadow-lg p-8">
              <p className="text-gray-700 whitespace-pre-line mb-6">
                {language === 'nl' ? 
                  'Ferry Safe helpt veiligheid de vereiste aandacht te geven en veiligheid persoonlijker en meer zichtbaar te maken. Ferry Safe is zich ervan bewust dat buiten veiligheid, productie ook belangrijk is voor een organisatie. Productie en veiligheid moeten daarom hand in hand gaan. Zonder productie geen inkomsten, maar zonder aandacht voor veiligheid loopt men het risico op incidenten en het stopzetten van de productie. Balans is daarbij de sleutelwoord.' :
                  'Ferry Safe helps to give safety the required attention and makes safety more personal and visible. Ferry Safe is aware that besides safety, production is also important for an organization. Production and safety must therefore go hand in hand. Without production there is no income, but without attention to safety, one runs the risk of incidents and production shutdowns. Balance is the key word here.'
                }
              </p>
              <p className="text-gray-700 whitespace-pre-line">
                {language === 'nl' ?
                  'Ferry Safe helpt organisaties met advies m.b.t. Arbo veiligheid, beschikt over technische tools voor het melden en registreren van observaties en (bijna)incidenten en een digitale interactieve trainingsomgeving. Verder ondersteunt Ferry Safe organisaties in het vergroten van het veiligheidsbewustzijn door zichtbaar te zijn op de werkvloer.' :
                  'Ferry Safe helps organizations with workplace safety advice, has technical tools for reporting and registering observations and (near) incidents, and provides a digital interactive training environment. Furthermore, Ferry Safe supports organizations in increasing safety awareness by being visible on the work floor.'
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Solutions Section */}
      <section id="solutions" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">{t('solutions.title')}</h2>
            <div className="w-24 h-1 bg-accent mx-auto mb-8"></div>
          </div>
          
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            {/* Arbo-veiligheid Card */}
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
                <img 
                  src={avgMockup} 
                  alt="Arbo-veiligheid Advies" 
                  className="w-full object-cover"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4 text-primary flex items-center">
                  {t('solutions.arboAdvice.title')}
                </h3>
                <ul className="space-y-4">
                  {t('solutions.arboAdvice.features').map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <RouterLink to="/services/arbo-advice">
                    <button className="bg-primary hover:bg-primary/90 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 w-full">
                      {t('services.arbo.cta')}
                    </button>
                  </RouterLink>
                </div>
              </div>
            </div>

            {/* Creatieve Posters Card */}
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
                <img 
                  src={posterMockup} 
                  alt="Creatieve Veiligheidsposters" 
                  className="w-full object-cover"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4 text-primary flex items-center">
                  {t('solutions.posters.title')}
                </h3>
                <ul className="space-y-4">
                  {t('solutions.posters.features').map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <RouterLink to="/services/posters">
                    <button className="bg-primary hover:bg-primary/90 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 w-full">
                      {t('services.posters.cta')}
                    </button>
                  </RouterLink>
                </div>
              </div>
            </div>

            {/* Safety App Card */}
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
                <img 
                  src={appMockup} 
                  alt="Safety App Screenshot" 
                  className="w-full object-cover"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  {t('solutions.safetyApp.title')}
                </h3>
                <ul className="space-y-4">
                  {t('solutions.safetyApp.features').map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <RouterLink to="/services/safety-app">
                    <button 
                      className="bg-primary hover:bg-primary/90 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 w-full"
                    >
                      {t('services.app.cta')}
                    </button>
                  </RouterLink>
                </div>
              </div>
            </div>

            {/* Interactive Presentation Card */}
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-t from-primary/40 to-transparent z-10"></div>
                <img 
                  src={presMockup} 
                  alt="Interactive Presentation" 
                  className="w-full object-cover"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  {t('solutions.presentation.title')}
                </h3>
                <ul className="space-y-4">
                  {t('solutions.presentation.features').map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <RouterLink to="/#contact">
                    <button 
                      className="bg-primary/80 hover:bg-primary/70 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 w-full"
                    >
                      <div className="flex items-center justify-center space-x-2">
                        <span>{language === 'nl' ? 'In testfase' : 'In testing phase'}</span>
                        <span role="img" aria-label="construction worker">👷🏼‍♂️</span>
                      </div>
                    </button>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Quote Section */}
      <section id="quote" className="py-20 bg-background">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-primary mb-4">{t('quote.title')}</h2>
            <p className="text-xl text-gray-600">{t('quote.subtitle')}</p>
          </div>

          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
            <div className="grid md:grid-cols-2 gap-8">
              {/* Left Column - Services Overview */}
              <div>
                <h3 className="text-2xl font-bold mb-6">{t('quote.services.title')}</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <span className="font-semibold block">{t('services.app.title')}</span>
                      <span className="text-gray-600">{t('quote.services.app')}</span>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <span className="font-semibold block">{t('services.training.title')}</span>
                      <span className="text-gray-600">{t('quote.services.training')}</span>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <span className="font-semibold block">{t('services.arbo.title')}</span>
                      <span className="text-gray-600">{t('quote.services.arbo')}</span>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <FerryCheckmark className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <span className="font-semibold block">{t('services.posters.title')}</span>
                      <span className="text-gray-600">{t('quote.services.posters')}</span>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Right Column - Contact Benefits */}
              <div>
                <h3 className="text-2xl font-bold mb-6">{t('quote.benefits.title')}</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <SparklesIcon className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">{t('quote.benefits.personal')}</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">{t('quote.benefits.flexible')}</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">{t('quote.benefits.free')}</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">{t('quote.benefits.fast')}</span>
                  </li>
                </ul>
                <div className="mt-8">
                  <a href="#contact">
                    <button className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300 w-full">
                      {t('quote.cta')}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t('contact.title')}</h2>
          <div className="max-w-lg mx-auto">
            {/* Temporary email button */}
            <div className="text-center">
              <a
                href="mailto:info@ferrysafe.nl"
                className="inline-block w-full bg-primary hover:bg-primary/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
              >
                {language === 'nl' ? 'Stuur ons een e-mail' : 'Send us an email'}
              </a>
              <p className="mt-4 text-gray-600 text-sm">
                {language === 'nl' 
                  ? 'Of mail direct naar: info@ferrysafe.nl' 
                  : 'Or email directly to: info@ferrysafe.nl'}
              </p>
            </div>

            {/* Original contact form (temporarily disabled)
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="name">{t('contact.name')}</label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
                  placeholder={t('contact.namePlaceholder')}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="email">{t('contact.email')}</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
                  placeholder={t('contact.emailPlaceholder')}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="message">{t('contact.message')}</label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
                  placeholder={t('contact.messagePlaceholder')}
                  required
                ></textarea>
              </div>
              {status.message && (
                <div className={`p-4 rounded-lg ${
                  status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                }`}>
                  {status.message}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-primary hover:bg-primary/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300 disabled:opacity-50"
              >
                {isSubmitting ? 
                  (language === 'nl' ? 'Verzenden...' : 'Sending...') :
                  t('contact.send')
                }
              </button>
            </form>
            */}
          </div>
        </div>
      </section>
    </>
  );
} 