import emailjs from '@emailjs/browser';

// Initialize EmailJS with your user ID from env
emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);

export const sendEmail = async (formData, service) => {
  try {
    const response = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        service_type: service,
        to_email: process.env.REACT_APP_CONTACT_EMAIL,
      }
    );
    return { success: true, response };
  } catch (error) {
    console.error('Email sending failed:', error);
    return { success: false, error };
  }
}; 