import React, { useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import presMockup from '../../assets/pics/FerrySafe-Website-Diensten-Presentatie-V01.jpg';

export default function TrainingPage() {
  const { t, language } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const benefits = [
    {
      title: {
        nl: 'Interactieve Leerervaring',
        en: 'Interactive Learning Experience'
      },
      description: {
        nl: 'Onze trainingen combineren theorie met praktische oefeningen en gamification elementen voor optimale betrokkenheid.',
        en: 'Our training combines theory with practical exercises and gamification elements for optimal engagement.'
      }
    },
    {
      title: {
        nl: 'Op Maat Gemaakt',
        en: 'Customized Content'
      },
      description: {
        nl: 'Trainingsmateriaal aangepast aan uw specifieke werkomgeving en veiligheidsuitdagingen.',
        en: 'Training material adapted to your specific work environment and safety challenges.'
      }
    },
    {
      title: {
        nl: 'Meetbare Resultaten',
        en: 'Measurable Results'
      },
      description: {
        nl: 'Volg de voortgang en effectiviteit van de training met gedetailleerde rapportages en analyses.',
        en: 'Track training progress and effectiveness with detailed reports and analytics.'
      }
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <div className="bg-accent/10 border-b border-accent/20">
        <div className="container mx-auto px-4 sm:px-6 py-3">
          <p className="text-center text-accent font-medium">
            {language === 'nl' ? 
              '🚧 Deze dienst is momenteel in ontwikkeling. Neem contact met ons op voor meer informatie.' :
              '🚧 This service is currently under development. Contact us for more information.'}
          </p>
        </div>
      </div>
      <div className="container mx-auto px-4 sm:px-6 pt-20">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl sm:text-4xl font-bold text-primary mb-8">{t('services.training.title')}</h1>
          
          {/* Introduction */}
          <div className="mb-12">
            <p className="text-lg mb-6">{t('services.training.description')}</p>
            <p className="text-gray-700">
              {language === 'nl' ? 
                'Onze interactieve veiligheidstrainingen zijn ontworpen om medewerkers actief te betrekken bij veiligheid op de werkplek. Door gebruik te maken van moderne leermethoden, waaronder escape room elementen en gamification, maken we veiligheidstraining boeiend en effectief.' :
                'Our interactive safety training is designed to actively engage employees in workplace safety. By using modern learning methods, including escape room elements and gamification, we make safety training engaging and effective.'}
            </p>
          </div>

          {/* Training Preview */}
          <div className="mb-12">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img 
                src={presMockup} 
                alt="Interactive Training Preview" 
                className="w-full h-auto object-cover"
              />
            </div>
          </div>

          {/* Benefits Grid */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow">
                <h3 className="text-xl font-bold text-primary mb-3">
                  {benefit.title[language]}
                </h3>
                <p className="text-gray-600">
                  {benefit.description[language]}
                </p>
              </div>
            ))}
          </div>

          {/* Features List */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-primary mb-6">
              {language === 'nl' ? 'Kenmerken' : 'Features'}
            </h2>
            <div className="grid sm:grid-cols-2 gap-4">
              {t('solutions.presentation.features').map((feature, index) => (
                <div key={index} className="flex items-start bg-gray-50 p-4 rounded-lg">
                  <CheckCircleIcon className="w-6 h-6 text-accent mr-3 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-700">{feature}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Call to Action */}
          <div className="bg-primary text-white rounded-lg p-6 sm:p-8 text-center mb-20">
            <h2 className="text-2xl font-bold mb-4">
              {language === 'nl' ? 
                'Klaar om uw veiligheidstraining naar een hoger niveau te tillen?' :
                'Ready to take your safety training to the next level?'}
            </h2>
            <p className="mb-6">
              {language === 'nl' ? 
                'Plan een demonstratie en ontdek hoe onze interactieve training uw team kan helpen.' :
                'Schedule a demonstration and discover how our interactive training can help your team.'}
            </p>
            <div className="mt-12 text-center">
              <button 
                onClick={() => {
                  window.location.href = '/#contact';
                  localStorage.setItem('scrollToContact', 'true');
                }}
                className="bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
              >
                {t('services.training.cta')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 