import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink, useLocation } from 'react-router-dom';
import { ChevronDownIcon, CheckCircleIcon, PhoneIcon, EnvelopeIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

// Import pages
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArboAdvicePage from './pages/services/ArboAdvicePage';
import PostersPage from './pages/services/PostersPage';
import SafetyAppPage from './pages/services/SafetyAppPage';
import TrainingPage from './pages/services/TrainingPage';

// Import components
import { useLanguage } from './context/LanguageContext';
import { NLFlag, GBFlag } from './components/Flags';
import { PurchaseFlow } from './components/PurchaseFlow';

// Import assets
import subLogo from './assets/FerrySafe-Sublogo.svg';

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Smooth scroll function
const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

// Handle navigation from other pages to sections
const handleExternalNavigation = (hash) => {
  window.location.href = '/';
  setTimeout(() => {
    const element = document.getElementById(hash.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
};

function App() {
  const { t, language, toggleLanguage } = useLanguage();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    // Handle hash navigation after page load
    const hash = window.location.hash;
    if (hash && window.location.pathname === '/') {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  const handleStartPurchase = (plan) => {
    setSelectedPlan(plan);
    setShowPurchaseFlow(true);
    window.scrollTo(0, 0);
  };

  const handleNavClick = (e, id) => {
    e.preventDefault();
    if (window.location.pathname !== '/') {
      window.location.href = `/#${id}`;
    } else {
      scrollToElement(id);
    }
    setIsMobileMenuOpen(false);
  };

  if (showPurchaseFlow) {
    return <PurchaseFlow plan={selectedPlan} onCancel={() => setShowPurchaseFlow(false)} />;
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-white">
        {/* Navigation */}
        <nav className="fixed w-full bg-white shadow-md z-50">
          <div className="container mx-auto px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <RouterLink to="/" className="h-8">
                  <img src={subLogo} alt="FerrySafe" className="h-full" />
                </RouterLink>
              </div>

              {/* Desktop Menu */}
              <div className="hidden md:flex items-center space-x-8">
                <RouterLink to="/" className="cursor-pointer hover:text-primary">{t('nav.home')}</RouterLink>
                <RouterLink to="/about" className="cursor-pointer hover:text-primary">{t('nav.about')}</RouterLink>
                <a 
                  href="#solutions" 
                  onClick={(e) => handleNavClick(e, 'solutions')} 
                  className="cursor-pointer hover:text-primary"
                >
                  {t('nav.solutions')}
                </a>
                <a 
                  href="#contact" 
                  onClick={(e) => handleNavClick(e, 'contact')} 
                  className="cursor-pointer hover:text-primary"
                >
                  {t('nav.contact')}
                </a>
                <button
                  onClick={toggleLanguage}
                  className="flex items-center justify-center hover:opacity-80 transition-opacity"
                  aria-label={language === 'nl' ? 'Switch to English' : 'Schakel naar Nederlands'}
                  title={language === 'nl' ? 'Switch to English' : 'Schakel naar Nederlands'}
                >
                  {language === 'nl' ? <NLFlag /> : <GBFlag />}
                </button>
              </div>

              {/* Mobile Menu Button */}
              <div className="md:hidden">
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="text-gray-600 hover:text-primary focus:outline-none"
                  aria-label="Toggle menu"
                >
                  {isMobileMenuOpen ? (
                    <XMarkIcon className="h-6 w-6" />
                  ) : (
                    <Bars3Icon className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>

            {/* Mobile Menu */}
            <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
              <div className="pt-4 pb-3 space-y-3">
                <RouterLink 
                  to="/"
                  className="block py-2 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('nav.home')}
                </RouterLink>
                <RouterLink 
                  to="/about"
                  className="block py-2 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('nav.about')}
                </RouterLink>
                <a 
                  href="#solutions"
                  className="block py-2 hover:text-primary"
                  onClick={(e) => handleNavClick(e, 'solutions')}
                >
                  {t('nav.solutions')}
                </a>
                <a 
                  href="#contact"
                  className="block py-2 hover:text-primary"
                  onClick={(e) => handleNavClick(e, 'contact')}
                >
                  {t('nav.contact')}
                </a>
                <div className="py-2">
                  <button
                    onClick={() => {
                      toggleLanguage();
                      setIsMobileMenuOpen(false);
                    }}
                    className="flex items-center space-x-2 hover:text-primary"
                    aria-label={language === 'nl' ? 'Switch to English' : 'Schakel naar Nederlands'}
                  >
                    {language === 'nl' ? <NLFlag /> : <GBFlag />}
                    <span className="ml-2">{language === 'nl' ? 'Switch to English' : 'Schakel naar Nederlands'}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services/arbo-advice" element={<ArboAdvicePage />} />
          <Route path="/services/posters" element={<PostersPage />} />
          <Route path="/services/safety-app" element={<SafetyAppPage />} />
          <Route path="/services/training" element={<TrainingPage />} />
        </Routes>

        {/* Footer - only show on homepage */}
        {window.location.pathname === '/' && (
          <footer className="bg-primary text-white py-12">
            <div className="container mx-auto px-6">
              <div className="grid md:grid-cols-4 gap-8">
                <div>
                  <h3 className="text-xl font-bold mb-4">FerrySafe</h3>
                  <p className="text-sm">{t('footer.trademark')}</p>
                </div>
                <div>
                  <h4 className="text-lg font-bold mb-4">{t('footer.quickLinks')}</h4>
                  <ul className="space-y-2">
                    <li><RouterLink to="/" className="hover:text-accent cursor-pointer">{t('nav.home')}</RouterLink></li>
                    <li><RouterLink to="/about" className="hover:text-accent cursor-pointer">{t('nav.about')}</RouterLink></li>
                    <li>
                      <a 
                        href="#solutions" 
                        onClick={(e) => handleNavClick(e, 'solutions')}
                        className="hover:text-accent cursor-pointer"
                      >
                        {t('nav.solutions')}
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#contact" 
                        onClick={(e) => handleNavClick(e, 'contact')}
                        className="hover:text-accent cursor-pointer"
                      >
                        {t('nav.contact')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-lg font-bold mb-4">{t('footer.contact')}</h4>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <EnvelopeIcon className="w-5 h-5 mr-2" />
                      <span>info@ferrysafe.nl</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-lg font-bold mb-4">{t('footer.followUs')}</h4>
                  <div className="flex space-x-4">
                    <a href="https://www.linkedin.com/in/leen-mooldijk-121b98141/" className="hover:text-accent">LinkedIn</a>
                  </div>
                </div>
              </div>
              <div className="border-t border-white/20 mt-8 pt-8 text-center">
                <p>&copy; {new Date().getFullYear()} FerrySafe. {t('footer.rights')}</p>
              </div>
            </div>
          </footer>
        )}
      </div>
    </Router>
  );
}

export default App;
