const FlagContainer = ({ children }) => (
  <div className="w-8 h-8 rounded-full overflow-hidden border-2 border-gray-200 flex items-center justify-center bg-gray-50">
    <div className="w-full h-full flex items-center justify-center scale-[1.4]">
      {children}
    </div>
  </div>
);

export const NLFlag = () => (
  <FlagContainer>
    <svg
      className="w-full h-full"
      viewBox="0 0 9 6"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="9" height="2" fill="#AE1C28"/>
      <rect y="2" width="9" height="2" fill="#FFFFFF"/>
      <rect y="4" width="9" height="2" fill="#21468B"/>
    </svg>
  </FlagContainer>
);

export const GBFlag = () => (
  <FlagContainer>
    <svg
      className="w-full h-full"
      viewBox="0 0 60 30"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
      </clipPath>
      <path d="M0,0 v30 h60 v-30 z" fill="#00247d"/>
      <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6"/>
      <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#cf142b" strokeWidth="4"/>
      <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10"/>
      <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6"/>
    </svg>
  </FlagContainer>
); 