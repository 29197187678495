export const nl = {
  nav: {
    home: 'Home',
    about: 'Over Ons',
    solutions: 'Diensten',
    testimonials: 'Referenties',
    contact: 'Contact',
    pricing: 'Prijzen'
  },
  hero: {
    title: 'Uw gezicht,\nop veiligheid',
    subtitle: 'Samen werken aan een veiligere werkomgeving door praktische oplossingen en persoonlijke betrokkenheid',
    cta: 'Bekijk Onze Diensten',
  },
  about: {
    title: 'Over Ferry Safe',
    pageTitle: 'Over Ons',
    content: 'Ferry Safe is bedacht en ontworpen om veiligheid persoonlijker en meer zichtbaar te maken binnen organisaties.\n\nVeiligheid op de werkvloer staat bij mij, Leen Mooldijk en oprichter van Ferry Safe, hoog in het vaandel. Jarenlange praktijkervaring leerde mij dat er op het gebied van veiligheid veel te behalen valt. Naast dat ik beschik over de diploma\'s voor Middelbaar- en Hogere Veiligheidskundige heb ik ook ruim 10 jaar ervaring in diverse rollen gerelateerd aan veiligheid binnen de sectoren transport en logistiek.\n\nMijn ruime operationele ervaring stelt mij ook in staat veiligheid te beoordelen vanuit het perspectief van de werkvloer. Van essentieel belang is dat opgestelde werkinstructies en procedures daadwerkelijk werkbaar zijn in de praktijk en dat de directie en de medewerkers op de werkvloer daaraan gecommitteerd zijn.\n\nFerry Safe zou als \'Safety Culture Model\' kunnen fungeren binnen organisaties om veiligheid de vereiste aandacht te geven.\n\nMocht u geïnspireerd zijn door de visie van Ferry Safe en heeft u interesse in één van de diensten van Ferry Safe, neem contact op. In gezamenlijkheid kan dan op vrijblijvende basis bekeken worden wat voor uw organisatie kan betekenen.\n\nMet vriendelijke groet,\n\nLeen Mooldijk\nHogere Veiligheidskundige\nOprichter van Ferry Safe'
  },
  solutions: {
    title: 'Onze Diensten',
    safetyApp: {
      title: 'Ferry Safe Safety App',
      features: [
        'Real-time incidentmeldingen',
        'Foto-documentatie',
        'Status tracking en analyses',
      ],
    },
    presentation: {
      title: 'Interactieve Presentatie',
      features: [
        'Gamified leerervaring',
        'Interactieve quizelementen',
        'Escape room componenten',
      ],
    },
    arboAdvice: {
      title: 'Advies over Arbo-veiligheid',
      description: 'Ferry Safe helpt organisaties om werkplekken veiliger te maken door het uitvoeren van risicoanalyses, het geven van beleidsadvies en trainingen. Samen zorgen wij voor een veilige en productieve omgeving.',
      features: [
        'Risicoanalyses op maat',
        'Praktisch beleidsadvies',
        'Veiligheidstrainingen',
        'Cultuuranalyse',
        'Compliance checks',
        'Implementatiebegeleiding'
      ]
    },
    posters: {
      title: 'Creatieve veiligheidsposters',
      description: 'De creatieve veiligheidsposters van Ferry Safe zijn ontworpen om veiligheid op een aansprekende manier onder de aandacht te brengen.',
      features: [
        'Gepersonaliseerde ontwerpen',
        'Duidelijke veiligheidsboodschap',
        'Professionele kwaliteit',
        'Aanpasbaar aan uw huisstijl',
        'Verschillende formaten beschikbaar',
        'Snelle levering'
      ]
    },
  },
  testimonials: {
    title: 'Wat Onze Klanten Zeggen',
    testimonial1: {
      text: 'FerrySafe heeft onze aanpak van werkplekveiligheid getransformeerd. De app maakt het melden van incidenten moeiteloos.',
      author: '- Jan Smit, Veiligheidsmanager',
    },
    testimonial2: {
      text: 'De interactieve presentaties hebben de betrokkenheid van medewerkers bij onze veiligheidstrainingen aanzienlijk verbeterd.',
      author: '- Sarah de Jong, HR Directeur',
    },
    testimonial3: {
      text: 'Sinds de implementatie van FerrySafe-oplossingen hebben we een daling van 40% in werkplekincidenten gezien.',
      author: '- Michael Bruin, Operationeel Manager',
    },
  },
  contact: {
    title: 'Neem Contact Op',
    name: 'Naam',
    email: 'E-mail',
    message: 'Bericht',
    send: 'Verstuur Bericht',
    namePlaceholder: 'Uw naam',
    emailPlaceholder: 'Uw e-mail',
    messagePlaceholder: 'Uw bericht',
  },
  footer: {
    quickLinks: 'Snelle Links',
    contact: 'Contact',
    followUs: 'Volg Ons',
    rights: 'Alle rechten voorbehouden.',
    trademark: 'FerrySafe® is een geregistreerd handelsmerk bij het Benelux-Bureau voor de Intellectuele Eigendom onder depotnummer 1346619.'
  },
  pricing: {
    title: 'Prijzen',
    subtitle: 'Kies het plan dat bij uw organisatie past',
    app: {
      title: 'Veiligheidsapp',
      starter: {
        name: 'Starter',
        price: '€4,99',
        period: 'per gebruiker / maand',
        description: 'Perfect voor kleine teams die willen starten met veiligheidsmanagement',
        users: 'Tot 25 gebruikers',
        features: [
          'Alle basis veiligheidsfuncties',
          'Incident rapportage',
          'Foto documentatie',
          'Basis analytics',
          'Email support'
        ],
        cta: 'Start nu'
      },
      professional: {
        name: 'Professional',
        price: '€3,99',
        period: 'per gebruiker / maand',
        description: 'Meest gekozen voor middelgrote organisaties',
        users: '25-100 gebruikers',
        features: [
          'Alle Starter functies',
          'Geavanceerde analytics',
          'Team management',
          'API toegang',
          'Prioriteit support'
        ],
        popular: 'Meest gekozen',
        cta: 'Start nu'
      },
      enterprise: {
        name: 'Enterprise',
        description: 'Voor grote organisaties met specifieke wensen',
        users: '100+ gebruikers',
        features: [
          'Alle Professional functies',
          'Aangepaste integraties',
          'Dedicated support',
          'SLA garantie',
          'On-premise optie'
        ],
        cta: 'Neem contact op'
      }
    },
    training: {
      title: 'Interactieve Training',
      description: 'Onze interactieve veiligheidstraining wordt op maat gemaakt voor uw organisatie',
      features: [
        'Gepersonaliseerde leerervaring',
        'Maatwerk scenario\'s',
        'Volledige rapportage',
        'Certificering mogelijk',
        'Onbeperkt aantal deelnemers'
      ],
      cta: 'Vraag offerte aan'
    }
  },
  purchase: {
    back: 'Terug naar prijzen',
    steps: {
      users: 'Gebruikers',
      payment: 'Betaling',
      company: 'Bedrijf',
      invite: 'Uitnodigen'
    },
    calculator: {
      title: 'Bereken het aantal gebruikers',
      recommendation: 'Aanbevelingen voor het aantal gebruikers:',
      officeRecommendation: 'Kantoormedewerkers: Managers, HR, administratie en andere kantoormedewerkers die incidenten moeten kunnen bekijken en analyseren.',
      fieldRecommendation: 'Veldmedewerkers: Alle medewerkers die actief zijn op de werkvloer en incidenten moeten kunnen melden.',
      officeUsers: 'Aantal kantoorgebruikers',
      fieldUsers: 'Aantal veldgebruikers',
      officeExample: 'bijv. managers, HR, administratie',
      fieldExample: 'bijv. operators, monteurs, magazijnmedewerkers',
      next: 'Volgende stap'
    },
    payment: {
      title: 'Overzicht en betaling',
      officeUsers: 'Kantoorgebruikers',
      fieldUsers: 'Veldgebruikers',
      pricePerUser: 'Prijs per gebruiker per maand',
      monthlyTotal: 'Maandelijks totaal',
      pay: 'Doorgaan naar betaling',
      processing: 'Bezig met verwerken...'
    },
    company: {
      title: 'Bedrijfsgegevens',
      name: 'Bedrijfsnaam',
      address: 'Adres',
      city: 'Plaats',
      postalCode: 'Postcode',
      country: 'Land',
      vatNumber: 'BTW-nummer',
      contactPerson: 'Contactpersoon',
      phone: 'Telefoonnummer',
      next: 'Volgende stap'
    },
    emails: {
      title: 'Gebruikers toevoegen',
      userEmail: 'E-mailadres gebruiker',
      complete: 'Afronden'
    }
  },
  services: {
    title: 'Onze Diensten',
    features: 'Kenmerken',
    benefits: 'Voordelen',
    arbo: {
      title: 'Advies over Arbo-veiligheid',
      description: 'Wij helpen jouw organisatie om werkplekken veiliger te maken door risicoanalyses, beleidsadvies en trainingen. Samen zorgen we voor een veilige en productieve omgeving.',
      cta: 'Vraag een adviesgesprek aan'
    },
    posters: {
      title: 'Creatieve Posters',
      description: 'Stimuleer veiligheid op de werkvloer met opvallende en op maat gemaakte posters voor in het gebouw en op het terrein.',
      cta: 'Bekijk voorbeelden'
    },
    app: {
      title: 'Veiligheidsapp',
      description: 'Met onze veiligheidsapp kunnen werknemers eenvoudig onveilige situaties melden, foto\'s toevoegen en oplossingen volgen. Ideaal voor een veiligere werkomgeving.',
      cta: 'Lees verder'
    },
    training: {
      title: 'Interactieve Trainingen',
      description: 'Betrek en train jouw team met onze gamified veiligheidspresentaties en escape room-elementen. Een leuke en effectieve manier om bewustzijn te vergroten.',
      cta: 'Meer informatie'
    }
  },
  quote: {
    title: 'Vraag een offerte aan',
    subtitle: 'Ontvang een op maat gemaakt voorstel voor jouw organisatie',
    services: {
      title: 'Onze diensten',
      app: 'Digitaal veiligheidsbeheer op maat',
      training: 'Engaging veiligheidstrainingen',
      arbo: 'Professionele risicoanalyses en advies',
      posters: 'Op maat gemaakte visuele communicatie'
    },
    benefits: {
      title: 'Waarom contact opnemen?',
      personal: 'Persoonlijk advies op maat',
      flexible: 'Flexibele oplossingen voor elk budget',
      free: 'Gratis eerste gesprek',
      fast: 'Snelle responstijd'
    },
    cta: 'Neem contact op'
  }
}; 